@import "variables";
@import "mixins";

.business-intro,
.business-intro-faq {
  overflow: hidden;
  font-family: "Inter", "Public Sans", "Roboto", sans-serif;
  h1,
  h2,
  h3 {
    font-family: "Ubuntu", "Public Sans", "Roboto", sans-serif;
  }
  h1 {
    font-weight: 700;
  }
  h2,
  h3 {
    font-weight: 500;
  }
  .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px $space-m-3xl;
    position: absolute;

    & > * {
      &.navbar {
        flex-grow: 1;
        background: none !important;
        align-items: center;
        @include mQ(992px) {
          width: 100%;
        }
        padding: 0;
        button.navbar-toggler {
          @include mQ(992px) {
            display: inline-block;
            width: fit-content;
            border: none;
            &:hover {
              background: none;
              svg {
                path {
                  box-shadow: 0px 1px red;
                }
              }
            }
          }
        }
        .collapse {
          padding-bottom: 0;

          li {
            a {
              color: $white;
            }
          }
          @include mQ(992px) {
            .navbar-nav {
              width: 100%;
            }
            text-align: left;
            ul * {
              justify-content: flex-start;
              align-items: flex-start;
            }
            li {
              width: 100%;
              margin-bottom: 8px;
              border-bottom: 1px solid $white;
            }
          }
        }
      }
    }

    .header-contact {
      & > div {
        margin-right: 30px;
        color: $intro-orange;
        font-weight: normal;
        svg {
          margin-right: 5px;
        }
      }
      @include mQ(992px) {
        flex-wrap: wrap;
        width: 100%;
      }
    }
  }

  .carousel-indicators {
    position: relative;
    margin: 15px auto;
    .carousel-indicator-item {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: $intro-orange;
      margin: auto 5px;
      &.active {
        background-color: $green;
      }
      &:hover {
        background-color: rgba($green, 0.3);
      }
    }
  }

  .hero-section {
    padding-top: 150px;
    background: linear-gradient(rgba($intro-blue, 0.7), rgba($intro-blue, 0.7)),
      url("../assets/Intro-Hero-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    & > div {
      padding: 0 $space-m-3xl $space-m-3xl;
      &:first-child {
        * {
          color: $white;
        }
        h2 {
          line-height: 54px;
          margin-bottom: 30px;
        }
        p {
          margin-bottom: 30px;
        }
        p,
        h4,
        ul {
          line-height: 28px;
        }
        ul {
          list-style: disc;
          list-style-position: inside;
          li {
            text-indent: clamp(-1.25rem, -1.21rem - 0.18vw, -1.38rem);
            margin-left: clamp(1.25rem, 1.21rem + 0.18vw, 1.38rem);
          }
        }
        h4 {
          color: $intro-orange;
          font-weight: 600;
        }
      }
      &:last-child {
        width: 100%;
        @include mQ(992px) {
          padding: 0;
        }
      }
    }
  }

  .header:has(.navbar-collapse.show) ~ .hero-section {
    transition: padding-top 0.3s;
    padding-top: 250px;
  }

  .contact-appointment {
    width: 100%;

    @include mQ(992px) {
      //box-shadow: 0px 5px 100px 0px rgba($light-grey,0.5);
    }
    iframe {
      html {
        background-color: red;
      }
    }
  }

  .services {
    padding: 0 $space-m-3xl $space-m-3xl;
    @include mQ(992px) {
      padding-top: 50px;
    }
    .services-list {
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: repeat(3, 1fr);
      gap: 25px;
      justify-items: center;
      @include mQ(992px) {
        grid-template-columns: auto;
      }
      .service-item {
        width: 100%;
        border: 1px solid $green;
        border-radius: 5px;
        padding: 50px 35px;
        background-color: $white;
        transition: all 0.3s;
        @include mQ-min(992px) {
          margin-top: -55px;
        }
        & > * {
          transition: all 0.3s;
          margin-bottom: 15px;
        }
        p {
          line-height: 28px;
        }
        h3 {
          color: $green;
        }
        a {
          color: $intro-orange;
          border-bottom: 2px solid;
          &:hover {
            font-weight: 700;
          }
        }

        &:hover,
        &.active {
          background-color: $green;
          * {
            color: $white;
            fill: $white;
          }
        }

        @include mQ(992px) {
          width: 100%;
          transition: all 0.3s;
          &:not(.show) {
            position: absolute;
            opacity: 0;
            z-index: -1;
            transition-duration: 0s;
          }
        }
      }
    }

    .service-details {
      .service-detail-item {
        margin-top: 50px;
        border-radius: 5px;
        background-color: $white;
        box-shadow: 0px 0px 3px 0px rgba($black, 0.3);
        padding: 25px;
        position: relative;
        display: none;
        & > * {
          margin-bottom: 15px;
        }
        a.close {
          position: absolute;
          top: 25px;
          right: 25px;
          width: 35px;
          height: 35px;
          svg {
            width: 100%;
            height: 100%;
            stroke: $intro-blue;
          }
        }

        p {
          line-height: 34px;
          font-size: $step-1;
        }
        h3 {
          color: $green;
        }
        h4 {
          font-size: $step-2;
          line-height: 58px;
        }
        div {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;
          img {
            width: 200px;
            height: auto;
            margin: 35px;
          }
        }

        &.active {
          display: block;
        }
      }
    }
  }

  .coverage-map {
    text-align: center;
    background-color: $intro-off-white;
    padding: $space-m-3xl;
    p:first-child {
      color: $green;
    }
    p:last-child {
      line-height: 28px;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  .testimonials {
    padding: $space-m-3xl;
    h2 {
      text-align: center;
      margin-bottom: 50px;
    }
    .testimonial-list {
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: repeat(3, 1fr);
      gap: 25px;
      justify-items: center;
      @include mQ(992px) {
        grid-template-columns: auto;
      }
      .testimonial-item {
        border-radius: 5px;
        background-color: $white;
        box-shadow: 0px 0px 3px 0px rgba($black, 0.3);
        padding: 25px;
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: 170px 35px auto;
        gap: 15px;
        img {
          margin: auto;
        }
        h3 {
          color: $green;
          height: fit-content;
        }
        @include mQ(992px) {
          width: 100%;
          transition: all 0.3s;
          &:not(.show) {
            position: absolute;
            opacity: 0;
            z-index: -1;
            transition-duration: 0s;
          }
        }
      }
    }
  }
  .api-cta {
    background-color: $intro-off-white;
    div {
      &:first-child {
        padding: 0px $space-m-3xl;
        h1 {
          font-weight: normal;
          line-height: 50px;
        }
        p {
          line-height: 28px;
        }
        button {
          max-width: 162px;
          height: 50px;
          padding: 8px;
          border-radius: 0px;
        }
      }
      &:last-child {
        padding-left: $space-m-3xl;
        @include mQ(992px) {
          padding: 0;
        }
      }
    }
    img {
      width: 100%;
    }
  }
  .footer {
    background-color: $green;
    padding: 0;
    * {
      color: $white;
    }
    a {
      font-weight: 200;
      margin-bottom: 8px;
      &:hover {
        font-weight: 400;
        border-bottom: 1px solid $white;
        color: $white;
        text-decoration: none;
      }
    }
    & > div:first-child {
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 0.5fr 0.2fr 0.2fr;
      justify-content: space-between;
      padding: $space-m-3xl $space-m-3xl 0;

      & > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
      @include mQ(992px) {
        grid-template-columns: auto;
        & > div {
          margin-bottom: 25px;
          a {
            margin-bottom: 8px;
          }
        }
      }
      .about-description {
        p {
          width: 50%;
          line-height: 36px;
          font-size: 16px;

          @include mQ(992px) {
            width: 100%;
            font-weight: 200;
            line-height: 28px;
          }
        }
      }
    }

    .divider {
      height: 0px;
      width: 100%;
      border-bottom: 1px solid rgba($white, 0.3);
    }
    .socials {
      padding: 30px $space-m-3xl;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      a {
        @include mQ(992px) {
          text-align: start;
          align-items: flex-start;
          margin-bottom: 8px;
        }
      }
      .links {
        display: flex;
        flex-direction: row;
        align-items: center;

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 15px;
          border-radius: 50%;
          width: 45px;
          height: 45px;
          padding: 10px;
          border: 1px solid $white;
          @include mQ(992px) {
            margin-right: 30px;
            margin-left: 0;
          }
          svg {
            stroke: $white;
            width: 100%;
            height: auto;
          }
          &:hover {
            background-color: rgba($white, 0.3);
          }
        }
      }
    }
  }
}

.business-intro-faq {
  background-color: $white;
  .hero-section {
    min-height: 500px;
    padding-left: $space-m-3xl;
    justify-content: center;
    & > div {
      padding: 0;
    }
  }
  .faq-list {
    padding: $space-m-3xl;
    .accordion-item {
      box-shadow: 0px 0px 4px 0px rgba($black, 0.1);
      border-radius: 5px;
      padding: 25px;
      margin-bottom: 10px;
      &:has(button.collapsed):hover {
        background-color: rgba($green, 0.3);
      }
    }
    button {
      height: 45px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-radius: 0px;
      background-color: transparent;
      color: $black;
      font-weight: normal;
      .minus {
        display: none;
      }
      &:not(.collapsed) {
        border-bottom: 1px solid rgba($black, 0.1);
        .plus {
          display: none;
        }
        .minus {
          display: block;
        }
      }
      &:focus {
        box-shadow: none;
        outline: none;
        border: none;
      }
    }
    .accordion-body {
      padding-top: 25px;
      padding-bottom: 15px;
      font-size: $step-0;
      font-weight: 200;
    }
    .loadings {
      width: fit-content;
    }
  }
}
